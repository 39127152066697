import React from 'react';
import PropTypes from 'prop-types';
import { useMedia } from 'react-use';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Grid from '../components/Grid';
import Typography from '../components/Typography';

const localizations = [
  {
    locale: 'nl-NL',
    slug: '/nl/blog/',
  },
];

const formatDate = (date, options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) =>
  new Intl.DateTimeFormat('en-US', options).format(new Date(date));

const Blog = ({ data, location }) => {
  const isMobile = useMedia('(max-width: 767px)');
  const isTablet = useMedia('(min-width: 768px) and (max-width: 991px)');
  const { allStrapiBlog: { nodes: posts = [] } = {} } = data;

  const [firstPost] = posts;

  return (
    <Layout location={location} locale="en" localizations={localizations} slug="/blog/">
      <Helmet
        title="Afosto Blog | Helping Retailers Succeed with Content"
        meta={[
          {
            name: 'description',
            content:
              "In Afosto's Blog you will find relevant articles in which we help retailers succeed in a scalable and efficient way.",
          },
        ]}
      >
        <html lang="en" />
      </Helmet>
      <Typography variant="h1" className="sr-only">
        Afosto blog
      </Typography>
      <Grid container className="py-lg-40 py-sm-56">
        {firstPost && (
          <>
            <Typography component="h2" className="sr-only">
              Most recent article
            </Typography>
            <Grid row className="mb-56 mb-md-64">
              <Grid column xs={12} md={6} lg={6}>
                <Link to={`/${firstPost.locale !== 'en' ? 'nl/' : ''}blog/${firstPost?.slug}/`}>
                  <picture className="blog-featured-post">
                    <source
                      srcSet={`${firstPost?.primary_image?.url}?w=510&h=384&c=1 1x, ${firstPost?.primary_image?.url}?w=1020&h=768&c=1 2x`}
                      media="(max-width: 767px)"
                    />
                    <source
                      srcSet={`${firstPost?.primary_image?.url}?w=330&h=330&c=1 1x, ${firstPost?.primary_image?.url}?w=660&h=660&c=1 2x`}
                      media="(max-width: 991px)"
                    />
                    <source
                      srcSet={`${firstPost?.primary_image?.url}?w=450&h=330&c=1 1x, ${firstPost?.primary_image?.url}?w=900&h=660&c=1 2x`}
                      media="(max-width: 1199px)"
                    />
                    <source
                      srcSet={`${firstPost?.primary_image?.url}?w=540&h=400&c=1 1x, ${firstPost?.primary_image?.url}?w=1080&h=800&c=1 2x`}
                    />
                    <img
                      src={`${firstPost?.primary_image?.url}?w=575&h=430&c=1`}
                      className="blog-featured-post-image"
                      alt={firstPost?.title}
                      width={575}
                      height={430}
                    />
                  </picture>
                </Link>
              </Grid>
              <Grid column xs={12} md={6} lg={6} className="pl-md-24 pl-lg-72">
                <Typography
                  className="mb-12 mb-lg-40 mt-20 mt-md-48 mt-lg-64"
                  variant={isMobile || isTablet ? 'body-small' : 'body'}
                >
                  {formatDate(firstPost?.published_at)}
                </Typography>
                <Typography
                  variant={(isMobile && 'h3') || (isTablet && 'h2') || 'h1'}
                  component="h3"
                  className="mb-20 mb-md-48 mb-lg-40"
                >
                  <Link
                    to={`/${firstPost.locale !== 'en' ? 'nl/' : ''}blog/${firstPost?.slug}/`}
                    className="hover-underline text-gray-1000"
                  >
                    {firstPost?.title}
                  </Link>
                </Typography>
                <Typography variant="body-small" color="primary" weight="medium">
                  <span>{Number(firstPost?.reading_time?.split(':')[1])} min.</span>
                  <span className="ml-8">reading time</span>
                </Typography>
              </Grid>
            </Grid>
          </>
        )}

        {posts.length > 2 && (
          <>
            <Typography variant={isMobile ? 'h4' : 'h3'} component="h2">
              Newest articles
            </Typography>
            <hr className="mt-16 mt-md-12 mt-lg-20 mb-32" />
            <Grid row>
              {posts?.slice(1)?.map(post => (
                <Grid
                  column
                  component="article"
                  xs={12}
                  md={6}
                  lg={4}
                  key={post?.slug}
                  className="mb-32 mb-lg-48 d-flex flex-column"
                >
                  <Link to={`/${post.locale !== 'en' ? 'nl/' : ''}blog/${post?.slug}/`}>
                    <picture className="blog-recent-post">
                      <source
                        srcSet={`${post?.primary_image?.url}?w=545&h=303&c=1 1x, ${post?.primary_image?.url}?w=1090&h=606&c=1 2x`}
                        media="(max-width: 767px)"
                      />
                      <source
                        srcSet={`${post?.primary_image?.url}?w=330&h=210&c=1 1x, ${post?.primary_image?.url}?w=660&h=420&c=1 2x`}
                        media="(max-width: 991px)"
                      />
                      <source
                        srcSet={`${post?.primary_image?.url}?w=290&h=215&c=1 1x, ${post?.primary_image?.url}?w=580&h=430&c=1 2x`}
                        media="(max-width: 1199px)"
                      />
                      <source
                        srcSet={`${post?.primary_image?.url}?w=350&h=260&c=1 1x, ${post?.primary_image?.url}?w=700&h=520&c=1 2x`}
                      />
                      <img
                        src={`${post?.primary_image?.url}?w=575&h=430&c=1`}
                        className="blog-recent-post-image"
                        alt={post?.title}
                        width={575}
                        height={430}
                      />
                    </picture>
                  </Link>
                  <Typography className="mb-12 mb-md-16 mt-16 mt-md-24">{formatDate(post?.published_at)}</Typography>
                  <Typography variant="h4" component="h3" className="mb-20 mb-md-24 mb-lg-32">
                    <Link
                      to={`/${post.locale !== 'en' ? 'nl/' : ''}blog/${post?.slug}/`}
                      className="hover-underline text-gray-1000"
                    >
                      {post?.title}
                    </Link>
                  </Typography>
                  {post?.reading_time && (
                    <Typography
                      variant={isMobile ? 'body-small' : 'body'}
                      color="primary"
                      weight="medium"
                      className="mt-auto"
                    >
                      <span>{Number(post?.reading_time?.split(':')[1])} min.</span>
                      <span className="ml-8">reading time</span>
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </Layout>
  );
};

Blog.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

Blog.defaultProps = {
  data: {},
  location: undefined,
};

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiBlog(sort: { fields: published_at, order: DESC }, filter: { locale: { eq: "en" } }) {
      nodes {
        author
        title
        published_at
        reading_time
        seo_description
        seo_title
        slug
        body
        locale
        primary_image {
          url
        }
      }
    }
  }
`;
